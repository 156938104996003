import React, { Component, Fragment } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'
import { withRouter } from 'next/router'

import numberWithSpaces from '../helpers/Numbers'
import { getSEOIdFromLocation } from '../helpers/Locations'
import { showProSubscribeDialog, showStateSubscribeDialog } from '../actions'
import { CDN_DOMAIN } from '../config/cdn'
import { generateObfuscatedLink } from '../helpers/SEO'
import typesURLMatching from '../datas/typesURLMatching'

class ProspectionCard extends Component {
    renderLease = () => {
        const attributes = {
            max: '',
            m2: '/m²',
            month: '/mois',
            year: '/an',
            unknown: 'Selon Dossier'
        }
        let text = ''
        if (
            this.props.lease_attributes !== 'unknown' &&
            _.isNumber(this.props.lease) &&
            this.props.lease !== 0
        ) {
            text += `${numberWithSpaces(this.props.lease)} € `
        }
        text += attributes[this.props.lease_attributes]

        return text
    }
    renderPrice = () => {
        const attributes = {
            max: '',
            m2: '/m²',
            unknown: 'Selon Dossier',
            lease_only: 'Location pure'
        }
        let text = ''
        if (
            this.props.price_attributes !== 'unknown' &&
            this.props.price_attributes !== 'lease_only' &&
            _.isNumber(this.props.price) &&
            this.props.price !== 0
        ) {
            text += `${numberWithSpaces(this.props.price)} € `
        }
        text += attributes[this.props.price_attributes]

        return text
    }
    render() {
        let {
            location,
            locations,
            id,
            prospection_id,
            reference_id,
            rest_surface,
            date,
            firstname,
            position,
            description,
            proposal_stats,
            phone_stats,
            view_stats,
            funding,
            lease,
            lease_attributes,
            price,
            price_attributes,
            user_status,
            display_number,
            style,
            router,
            activity,
            buy,
            is_field,
            verbosity,
            isMobileOnly
        } = this.props

        /* console.log('contentPrunning: ', this.props.contentPrunning)
        console.log('locationURLsMatching: ', this.props.locationURLsMatching) */

        const typesMapping = {
            'local-commercial': 'local_commercial',
            'achat-fonds-commerce': 'fonds_de_commerce',
            'terrain': 'terrain'
        }

        let locationSEO = null
        if (location.type === 'departement') {
            locationSEO = getSEOIdFromLocation(location)
        }
        if (location.type === 'commune' || location.type === 'commune_all') {
            locationSEO = getSEOIdFromLocation(location)
        }
        if (location.type === 'country' && location.id === 'country_fr') {
            locationSEO = 'france'
        }

        let type = null
        if (router.query.type) {
            type = router.query.type
        }
        if (!router.query.type) {
            if (is_field) {
                type = 'terrain'
            }
            if (!is_field) {
                type = 'local-commercial'
            }
        }

        const new_view_stats = view_stats < 30 ? 29 + view_stats : view_stats
        const new_proposal_stats =
            proposal_stats < 8 ? 8 + proposal_stats : proposal_stats

        return (
            <div
                className={this.props.className}
                style={{
                    marginLeft: 5,
                    marginRight: 5,
                    padding: 10,
                    border: '1px solid #dfdfdf',
                    borderRadius: 5,
                    display: 'flex',
                    maxWidth: 345,
                    height: 220,
                    flexDirection: 'column',
                    fontFamily: 'Lato, Roboto, Helvetica, Arial, sans-serif',
                    color: 'black',
                    ...style
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}
                >
                    {firstname &&
                        firstname !== '' && (
                            <div
                                style={{
                                    fontWeight: 900,
                                    fontSize: 18
                                }}
                            >
                                {_.upperFirst(_.toLower(firstname))}
                            </div>
                        )}
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: 'calc(100% - 81px)'
                        }}
                    >
                        {date && (
                            <div style={{ fontWeight: 300, fontSize: 11 }}>
                                {date}
                            </div>
                        )}
                        <div
                            style={{
                                fontWeight: 900,
                                fontSize: 12,
                                marginTop: 2,
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}
                        >
                            {position ? position + ', ' : ''}
                            {activity}
                        </div>
                    </div>
                    <div>
                        <img
                            src={`${CDN_DOMAIN}/static/svg/etiquette-aquereur-verifie.svg`}
                            alt=""
                            style={{
                                height: 25,
                                width: 81
                            }}
                            loading="lazy"
                        />
                    </div>
                </div>
                <div
                    style={{
                        fontWeight: 500,
                        lineHeight: 1.4,
                        fontSize: 13
                    }}
                >
                    <h3
                        style={{
                            fontSize: 13,
                            fontWeight: 900,
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            marginTop: 5,
                            marginBottom: 2
                        }}
                    >
                        <a
                            className="annonce-title-link"
                            href={`/annonces/${typesURLMatching[type]}/${locationSEO}-${
                                rest_surface.max
                            }-m2/${reference_id}${_.findKey(
                                locations,
                                l => l.id === location.id
                            )}`}
                        >
                            {description.title}
                        </a>
                    </h3>
                    <div style={{ display: 'flex' }}>
                        {!this.props.disableOtherLocations &&
                            <div
                                style={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}
                            >
                                {_.truncate(description.message, {
                                    length: 100
                                })}
                            </div>
                        }
                        {this.props.disableOtherLocations &&
                            <div>
                                {_.truncate(description.message, {
                                    length: isMobileOnly ? 95 : 150
                                })}
                                {generateObfuscatedLink(
                                    `/annonces/${typesURLMatching[type]}/${locationSEO}-${
                                        rest_surface.max
                                    }-m2/${reference_id}${_.findKey(
                                        locations,
                                        l => l.id === location.id
                                    )}`,
                                    "Voir plus",
                                    "green-olink",
                                    {
                                        fontSize: 12,
                                        paddingLeft: 2
                                    }
                                )}
                            </div>
                        }
                        {!this.props.disableOtherLocations && locations &&
                            locations.filter(l => l.id !== location.id)
                                .length === 0 &&
                                generateObfuscatedLink(
                                    `/annonces/${typesURLMatching[type]}/${locationSEO}-${
                                        rest_surface.max
                                    }-m2/${reference_id}${_.findKey(
                                        locations,
                                        l => l.id === location.id
                                    )}`,
                                    "Voir plus",
                                    "green-olink",
                                    {
                                        fontSize: 12,
                                        paddingLeft: 2,
                                        display: 'flex',
                                        alignItems: 'flex-end',
                                        whiteSpace: 'nowrap'
                                    }
                                )
                            }
                    </div>
                    {!this.props.disableOtherLocations && locations &&
                        locations.filter(l => l.id !== location.id).length >
                            0 && (
                            <div
                                style={{
                                    display: 'flex',
                                    width: '100%'
                                }}
                            >
                                <div
                                    style={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis'
                                    }}
                                >
                                    Recherche également à{' '}
                                    {locations
                                        .filter(l => (l.id !== location.id) || !this.props.ligne5LocationsIds || (this.props.ligne5LocationsIds && this.props.ligne5LocationsIds.indexOf(`${l.nom}_${l.id}`) === -1))
                                        .map(l => {
                                            if (this.props.contentPrunning && this.props.locationURLsMatching) {
                                                if (l.type === 'commune' || l.type === 'commune_all') {
                                                    const prunningInfos = this.props.contentPrunning[l.code] ? this.props.contentPrunning[l.code] : null
                                                    if (prunningInfos) {
                                                        const annonceType = type === 'local-commercial' ? price_attributes === 'lease_only' ? 'local-commercial' : 'achat-fonds-commerce' : type
                                                        const prunning = prunningInfos[typesMapping[annonceType]] === 0 ? true : false
                                                        if (prunning) {
                                                            return l.libelle
                                                                .replace(
                                                                    /\(.+\)/gi,
                                                                    ''
                                                                )
                                                                .trim()
                                                        }
                                                    }
                                                }
                                            }
                                            return generateObfuscatedLink(
                                                `/annonces/${typesURLMatching[type]}/${getSEOIdFromLocation(
                                                    l
                                                )}-${
                                                    rest_surface.max
                                                }-m2/${reference_id}${_.findKey(
                                                    locations,
                                                    l2 => l2.id === l.id
                                                )}`,
                                                l.libelle
                                                    .replace(/\(.+\)/gi, '')
                                                    .trim(),
                                                "annonce-title-link",
                                                { fontSize: 13 }
                                            )
                                        })
                                        .map((l, i) => (
                                            <Fragment key={i}>
                                                {i > 0 ? ', ' : ''}
                                                {l}
                                            </Fragment>
                                        ))}
                                </div>
                                {generateObfuscatedLink(
                                    `/annonces/${typesURLMatching[type]}/${locationSEO}-${
                                        rest_surface.max
                                    }-m2/${reference_id}${_.findKey(
                                        locations,
                                        l => l.id === location.id
                                    )}`,
                                    "Voir plus",
                                    "green-olink",
                                    {
                                        fontSize: 12,
                                        paddingLeft: 2,
                                        display: 'flex',
                                        alignItems: 'flex-end',
                                        whiteSpace: 'nowrap'
                                    }
                                )}
                            </div>
                        )}
                    {!this.props.disableOtherLocations && locations &&
                        locations.filter(l => l.id !== location.id).length ===
                            0 && <div style={{ height: 18 }} />}
                </div>
                <div
                    style={{
                        display: 'flex',
                        marginTop: 10,
                        height: 25
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '50%'
                        }}
                    >
                        {new_proposal_stats > 0 && (
                            <img
                                src={`${CDN_DOMAIN}/static/svg/ico-dossier.svg`}
                                alt=""
                                style={{
                                    height: 25,
                                    width: 25,
                                    marginRight: 10
                                }}
                                loading="lazy"
                            />
                        )}
                        <span
                            style={{
                                fontWeight: 900,
                                fontSize: 12
                            }}
                        >
                            {new_proposal_stats > 0 && (
                                <Fragment>
                                    {new_proposal_stats}
                                    {!verbosity && (
                                        <Fragment>
                                            {' '}
                                            dossier
                                            {new_proposal_stats > 1 ? 's' : ''}
                                        </Fragment>
                                    )}
                                    {verbosity && (
                                        <Fragment>
                                            {' '}
                                            dossier
                                            {new_proposal_stats > 1
                                                ? 's'
                                                : ''}{' '}
                                            reçu
                                            {new_proposal_stats > 1 ? 's' : ''}
                                        </Fragment>
                                    )}
                                </Fragment>
                            )}
                        </span>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '50%'
                        }}
                    >
                        {display_number &&
                            phone_stats > 0 && (
                                <img
                                    src={`${CDN_DOMAIN}/static/svg/ico-telephone.svg`}
                                    alt=""
                                    style={{
                                        height: 25,
                                        width: 25,
                                        marginRight: 10
                                    }}
                                    loading="lazy"
                                />
                            )}
                        <span
                            style={{
                                fontWeight: display_number ? 900 : 300,
                                fontSize: display_number ? 12 : 11,
                                fontStyle: display_number ? 'normal' : 'italic'
                            }}
                        >
                            {display_number &&
                                phone_stats > 0 && (
                                    <Fragment>
                                        {!verbosity && (
                                            <Fragment>
                                                {phone_stats} appel
                                                {phone_stats > 1 ? 's' : ''}
                                            </Fragment>
                                        )}
                                        {verbosity && (
                                            <Fragment>
                                                {phone_stats} appel
                                                {phone_stats > 1 ? 's' : ''} de
                                                professionnels
                                            </Fragment>
                                        )}
                                    </Fragment>
                                )}
                            {/* !display_number && "coordonnées non visible" */}
                        </span>
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        marginTop: 10,
                        height: 25
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '50%'
                        }}
                    >
                        {new_view_stats > 0 && (
                            <img
                                src={`${CDN_DOMAIN}/static/svg/ico-vue.svg`}
                                alt=""
                                style={{
                                    height: 25,
                                    width: 25,
                                    marginRight: 10
                                }}
                                loading="lazy"
                            />
                        )}
                        <span
                            style={{
                                fontWeight: 900,
                                fontSize: 12
                            }}
                        >
                            {new_view_stats > 0 && (
                                <Fragment>
                                    {new_view_stats}
                                    {!verbosity && (
                                        <Fragment>
                                            {' '}
                                            vue
                                            {new_view_stats > 1 ? 's' : ''}
                                        </Fragment>
                                    )}
                                    {verbosity && (
                                        <Fragment>
                                            {' '}
                                            professionnel
                                            {new_view_stats > 1 ? 's' : ''} ont
                                            vu la recherche
                                        </Fragment>
                                    )}
                                </Fragment>
                            )}
                        </span>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '50%'
                        }}
                    >
                        {(user_status === 2 ||
                            (user_status !== 2 && funding)) && (
                            <Fragment>
                                <img
                                    src={`${CDN_DOMAIN}/static/svg/ico-financement.svg`}
                                    alt=""
                                    style={{
                                        height: 25,
                                        width: 25,
                                        marginRight: 10
                                    }}
                                    loading="lazy"
                                />
                                <span
                                    style={{
                                        fontWeight: 900,
                                        fontSize: 12
                                    }}
                                >
                                    Financement validé
                                </span>
                            </Fragment>
                        )}
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignContent: 'center',
                        marginTop: 10
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignContent: 'center',
                            width: 'calc(100% - 100px)'
                        }}
                    >
                        <span
                            style={{
                                fontWeight: 900,
                                fontSize: 12,
                                lineHeight: 1.5,
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}
                        >
                            Loyer max : {this.renderLease()}
                            {/*<span style={{ fontWeight: 400 }}>
                                    HC/HT/Mois
                            </span>*/}
                        </span>
                        {price_attributes !== 'lease_only' && (
                            <span
                                style={{
                                    fontWeight: 900,
                                    fontSize: 12,
                                    lineHeight: 1.5,
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}
                            >
                                Prix max : {this.renderPrice()}
                            </span>
                        )}
                    </div>
                    <a
                        href="javascript:void(0)"
                        onClick={() =>
                            this.props.isState
                                ? this.props.showStateSubscribeDialog()
                                : this.props.showProSubscribeDialog()
                        }
                        className="uec-button-small"
                        style={{
                            width: 100,
                            lineHeight: 1.5
                        }}
                    >
                        Contacter
                    </a>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        { showStateSubscribeDialog, showProSubscribeDialog },
        dispatch
    )
}

export default withRouter(
    connect(
        null,
        mapDispatchToProps
    )(ProspectionCard)
)
